import React, { useState, useContext } from "react"
import tw, { styled, css } from "twin.macro"
import Collapse from "@material-ui/core/Collapse"
import { GeneralContext } from "../contexts/generalContext"
import { translateCustom } from "../langs/langHelpers"

const TablaPedidos = ({ pedidos, tipoDeUsuario }) => {
  const generalContext = useContext(GeneralContext)
  const { t, langCode } = generalContext
  return (
    <div>
      <table css={tw`w-full block md:table`} style={{}}>
        <thead css={tw`hidden md:table-header-group`}>
          <tr>
            <CajaTH colSpan="2">{t.private.table.item}</CajaTH>
            {tipoDeUsuario === "representante" && (
              <CajaTH>{t.private.table.client}</CajaTH>
            )}
            <CajaTH>{t.private.table.date}</CajaTH>
            <CajaTH css={tw`text-center`}>{t.private.table.estado}</CajaTH>
            <CajaTH></CajaTH>
          </tr>
        </thead>
        <tbody>
          {pedidos.map((pedido, i) => {
            const {
              id,
              ref,
              cliente_id,
              cliente_nombre,
              estado,
              fecha_pedido,
              fecha_prevista_reparto,
              fecha_prevista_entrega,
              modulos,
            } = pedido
            return (
              <CajaTR key={i}>
                <CajaTD>
                  <Destacado>{id}</Destacado>
                </CajaTD>
                <CajaTD>
                  {t.private.table.ref} <Destacado>{ref}</Destacado>
                  <br />
                  {t.private.table.nModules} <Destacado>{modulos}</Destacado>
                </CajaTD>
                {tipoDeUsuario === "representante" && (
                  <CajaTD>
                    {t.private.table.clientNumber}{" "}
                    <Destacado>{cliente_id}</Destacado>
                    <br />
                    {cliente_nombre}
                  </CajaTD>
                )}
                <CajaTD>{fecha_pedido}</CajaTD>
                <CajaTD css={tw`text-center`}>
                  <div>
                    <EstadoPedido estado={filterPedidoEstado(estado)} />
                  </div>
                  <div css={tw`text-obsssmall2 font-semibold pt-1`}>
                    {filterPedidoEstado(estado, langCode)}
                  </div>
                </CajaTD>
                <CajaTD>
                  {t.private.table.distribution}{" "}
                  <Destacado>{fecha_prevista_reparto}</Destacado>
                  {fecha_prevista_entrega && (
                    <>
                      <br />
                      {t.private.table.delivery}{" "}
                      <Destacado>{fecha_prevista_entrega}</Destacado>
                    </>
                  )}
                </CajaTD>

                <PedidoMovil
                  refNumber={ref}
                  estado={estado}
                  modulos={modulos}
                  id={id}
                  cliente_id={cliente_id}
                  cliente_nombre={cliente_nombre}
                  fecha_pedido={fecha_pedido}
                  tipoDeUsuario={tipoDeUsuario}
                  fecha_prevista_entrega={fecha_prevista_entrega}
                  fecha_prevista_reparto={fecha_prevista_reparto}
                  t={t}
                  langCode={langCode}
                />
              </CajaTR>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
export default TablaPedidos

const filterPedidoEstado = (estado, langCode) => {
  if (!langCode) {
    return estado.replace("&Oacute;", "Ó")
  }
  return translateCustom(
    "estadoPedidos",
    estado.replace("&Oacute;", "Ó"),
    langCode
  )
}

const PedidoMovil = ({
  refNumber,
  estado,
  modulos,
  id,
  cliente_id,
  cliente_nombre,
  fecha_pedido,
  tipoDeUsuario,
  fecha_prevista_entrega,
  fecha_prevista_reparto,
  langCode,
  t,
}) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <CajaMobile>
      <div tw="pb-3" onClick={() => setOpen(!isOpen)}>
        <div tw="text-obmed2">
          {t.private.table.ref} <Destacado>{refNumber}</Destacado>
        </div>
        <div>
          <EstadoPedido estado={filterPedidoEstado(estado)} />{" "}
          <span tw="text-obsssmall2 font-semibold ml-3">
            {filterPedidoEstado(estado, langCode)}
          </span>
        </div>
      </div>
      <MobileHr />
      <Collapse in={isOpen}>
        <div>
          <div>
            <div>
              {t.private.table.nModules} <Destacado>{modulos}</Destacado>
            </div>
            <div>
              {t.private.table.item} <Destacado>{id}</Destacado>
            </div>
          </div>
          <MobileHr />
          {tipoDeUsuario === "representante" && (
            <div>
              {t.private.table.clientNumber} <Destacado>{cliente_id}</Destacado>
              <br />
              {cliente_nombre}
            </div>
          )}
          <MobileHr />
          <div>
            {t.private.table.date} {fecha_pedido}
          </div>
          <MobileHr />
          <div>
            {t.private.table.distribution}:{" "}
            <Destacado>{fecha_prevista_reparto}</Destacado>
            {fecha_prevista_entrega && (
              <>
                <br />
                {t.private.table.delivery}{" "}
                <Destacado>{fecha_prevista_entrega}</Destacado>
              </>
            )}
          </div>
          <MobileHr />
        </div>
      </Collapse>
    </CajaMobile>
  )
}

const EstadoPedido = ({ estado }) => {
  return (
    <svg
      width={72}
      height={12}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={tw`inline-block`}
    >
      <path stroke="#fff" strokeWidth={3} d="M5 6h65" />
      <circle cx={5.5} cy={6} r={5.5} fill="#fff" />
      {estado === "PENDIENTE FABRICACIÓN" && (
        <circle cx={5.5} cy={6} r={3.5} fill="#000" />
      )}
      <circle cx={25.5} cy={6} r={5.5} fill="#fff" />
      {estado === "FABRICACIÓN" && (
        <circle cx={25.5} cy={6} r={3.5} fill="#000" />
      )}
      <circle cx={45.5} cy={6} r={5.5} fill="#fff" />
      {estado === "EN CARGA" && <circle cx={45.5} cy={6} r={3.5} fill="#000" />}
      <circle cx={65.5} cy={6} r={5.5} fill="#fff" />
      {estado === "EN RUTA - ENTREGADO" && (
        <circle cx={65.5} cy={6} r={3.5} fill="#000" />
      )}
    </svg>
  )
}

const CajaTR = styled.tr`
  ${tw`block md:table-row text-gris1`}
`
const CajaTH = styled.th`
  ${tw`text-obmed2 font-normal text-left uppercase py-6 pr-3`}
`

const CajaTD = styled.td`
  ${tw`hidden md:table-cell border-t border-marron2 text-obmed2 font-light align-top py-4  md:pr-3`}
`
const CajaMobile = styled.td`
  ${tw`block md:hidden mb-3`}
`

const Destacado = styled.strong`
  ${tw`font-bold`}
`
const MobileHr = styled.hr`
  ${tw`border-t md:hidden`}
  border-top: 1px solid #E0D6C8;
`

const estilosCaja = css``
