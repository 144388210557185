import React from "react"
import tw, {styled} from 'twin.macro';

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'


const Selector = ({titulo, items, onChange, name}) => {
    return (
        <div css={tw`block md:inline-block max-w-xs w-full pb-3 md:mr-8`}>
            <Autocomplete
                options={items}
                getOptionLabel={(option) => option.selectorTitle}
                renderInput={(params) => <StyledTextField {...params} label={titulo} css={tw`text-marron1`} />}
                onChange={(event, newValue) => {
                    onChange(name, newValue);
                  }}
            />
        </div>
    )
}
export default Selector

const StyledTextField = styled(TextField)`
    ${tw`bg-white`}
    && {
        
    }
    div.MuiInputBase-root{
        ${tw`mt-0`}
    }
    div.MuiAutocomplete-root{
        
        ${tw`bg-white`}
    }
    label.MuiFormLabel-root{
        ${tw`pl-4`}
        ${tw`py-3`}
        ${tw`text-obmed2`}
        ${tw`text-marron1`}
        transform: none!important;
    }
    label.MuiFormLabel-root.Mui-focused,
    label.MuiFormLabel-root.MuiFormLabel-filled{
        opacity: 0;
    }
    input.MuiInputBase-input{
        outline: none;
        outline-style: none;
        box-shadow: none;
        border-color: transparent;
        ${tw`text-obmed2`}
        ${tw`text-marron1`}
    }
    .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-of-type{
        ${tw`pl-4`}
        ${tw`py-3`}
        
    }
    .MuiInput-underline:after,
    .MuiInput-underline:before{
        display: none;
    }
`