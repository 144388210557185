import React, { useContext } from "react"
import { PrivateContext } from "./privateContext"
import tw, { styled, css } from "twin.macro"
import { GeneralContext } from "../contexts/generalContext"

const PrivateAreaNavbar = () => {
  const generalContext = useContext(GeneralContext)
  const { t } = generalContext
  const privateContext = useContext(PrivateContext)
  //onsole.log(privateContext)
  return (
    <div
      css={tw`border-t border-b md:border-0 border-marron3 py-3 md:pt-8 md:pb-0 text-obmed text-gris1`}
    >
      <span tw="mr-3">
        {t.private.hi} <strong>{privateContext.userName}</strong>.
      </span>
      <span
        css={tw`text-marron1 uppercase cursor-pointer`}
        onClick={privateContext.logout}
      >
        {t.private.logout}
      </span>
    </div>
  )
}
export default PrivateAreaNavbar
