import React, { useEffect, useState, useContext } from "react"
import { Link, graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import each from "lodash.foreach"
import filter from "lodash.filter"
import uniqBy from "lodash.uniqby"
import { GeneralContext } from "../contexts/generalContext"

import isClient from "../helpers/isClient"

import {
  TransitionState,
} from "../helpers/DummyGatsbyPluginTransitionLink"
import { Spring, animated as a } from "@react-spring/web"
import Footer from "../components/footer"
import BreadCrumb from "../components/breadcrumb"
import Seo from "../components/seo"

import { PrivateContext } from "../areaprivada/privateContext"
import PrivateAreaNavBar from "../areaprivada/navbar"
import Selector from "../areaprivada/selector"
import TablaPedidos from "../areaprivada/tablaPedidos"

const PagePrivada = props => {
  const { pageContext } = props

  const lang = pageContext.lang;

  const privateContext = useContext(PrivateContext)
  const generalContext = useContext(GeneralContext)
  const { t } = generalContext

  //Filtros por referencia y/o cliente
  const [filtros, setFiltros] = useState({
    ref: null,
    clienteId: null,
  })
  const [orden, setOrden] = useState("")
  const [pedidosFiltradosYOrdenados, setPedidosFiltradosYOrdenados] = useState(
    []
  )

  const [pedidosClientes, setPedidosClientes] = useState([])
  const [pedidosRefs, setPedidosRefs] = useState([])

  const triggerTransition = ({to}) => {
    if(typeof window !== 'undefined'){
      window.location.href = to
    }
  }
  if (!privateContext.isUserLogged) {
    if (isClient) {
      triggerTransition({ to: "/area-privada" })
    }
  }
  if (privateContext.pedidosDeUsuario === null) {
    privateContext.pidePedidosDeUsuario()
  }

  // const sitePageContext = props.data.sitePage.context

  const filtraYOrdenaPedidos = (propiedadAFiltrar, valor) => {
    //onsole.log("filtraYOrdenaPedidos")
    let pedidosFiltrados = privateContext.pedidosDeUsuario
    switch (propiedadAFiltrar) {
      case "referencia":
        if (valor !== null) {
          pedidosFiltrados = filter(privateContext.pedidosDeUsuario, {
            ref: valor.ref,
          })
        }
        break

      case "cliente":
        if (valor !== null) {
          pedidosFiltrados = filter(privateContext.pedidosDeUsuario, {
            cliente_id: valor.clienteId,
          })
        }
        break

      default:
        break
    }
    // const pedidosFiltrados = filter(privateContext.pedidosDeUsuario, (pedido) => {

    // })

    // setPedidosFiltradosYOrdenados(pedidosFiltrados)
    setPedidosFiltradosYOrdenados(pedidosFiltrados)
  }

  //Actualiza los selectores
  useEffect(() => {
    const actualizaDatosDeSelectores = () => {
      const pClientes = []
      const pRefs = []
      each(privateContext.pedidosDeUsuario, pedido => {
        const pCliente = {
          // ...pedido,
          clienteId: pedido.cliente_id,
          selectorTitle: pedido.cliente_id + " " + pedido.cliente_nombre,
        }
        pClientes.push(pCliente)
        const pRef = { ...pedido, selectorTitle: pedido.ref }
        pRefs.push(pRef)
      })
      const clientsFiltered = uniqBy(pClientes, "clienteId")
      setPedidosClientes(clientsFiltered)
      setPedidosRefs(pRefs)
    }
    actualizaDatosDeSelectores()
    filtraYOrdenaPedidos()
  }, [privateContext.pedidosDeUsuario])

  return (
    <>
      <Seo
        pageContext={pageContext}
      />
      <TransitionState>
        {({ transitionStatus, exit, entry }) => {
          const isMounting = ["entering", "entered"].includes(transitionStatus)
          const isExiting = transitionStatus === "exiting"
          return (
            <>
              <div css={tw`px-5 md:px-9 overflow-x-visible`}>
                <div
                  css={tw`max-w-screen-xl my-0 mx-auto px-0 font-obbody overflow-x-visible`}
                >
                  <article>
                    <div css={tw`relative`}>
                      <BreadCrumb
                        isMounting={isMounting}
                        pageContext={pageContext}
                      />

                      <Spring
                        to={{
                          transform: `translateY(${
                            isMounting ? "0rem" : isExiting ? "-2rem" : "10rem"
                          })`,
                          opacity: isMounting ? 1 : 0,
                        }}
                      >
                        {styles => (
                          <a.div style={styles}>
                            <div tw="md:flex md:flex-row-reverse md:justify-between mt-8 md:border-t border-marron2">
                              <PrivateAreaNavBar />
                              <div tw="md:flex-1 pt-6 pb-4">
                                <Selector
                                  titulo={t.private.searchByRef}
                                  items={pedidosRefs}
                                  name="referencia"
                                  onChange={filtraYOrdenaPedidos}
                                />
                                {privateContext.tipoDeUsuario ===
                                  "representante" && (
                                  <Selector
                                    titulo={t.private.searchByClient}
                                    items={pedidosClientes}
                                    name="cliente"
                                    onChange={filtraYOrdenaPedidos}
                                  />
                                )}
                              </div>
                            </div>
                            <div tw="pb-8">
                              {pedidosFiltradosYOrdenados === null && (
                                <RespuestaError>
                                  {t.private.loadingData}
                                </RespuestaError>
                              )}
                              {pedidosFiltradosYOrdenados?.length === 0 && (
                                <RespuestaError>
                                  {t.private.noResult}
                                </RespuestaError>
                              )}
                              {pedidosFiltradosYOrdenados?.length > 0 && (
                                <TablaPedidos
                                  pedidos={pedidosFiltradosYOrdenados}
                                  tipoDeUsuario={privateContext.tipoDeUsuario}
                                />
                              )}
                            </div>
                          </a.div>
                        )}
                      </Spring>
                    </div>
                  </article>
                </div>
              </div>
              <Footer isMounting={isMounting} lang={lang} langSettings={pageContext.langSettings} />
            </>
          )
        }}
      </TransitionState>
    </>
  )
}
const twInputTextContent = tw`w-full pb-px lg:pb-0 md:pr-px`
const twInputText = tw`w-full mt-1 border-white border-2 bg-white placeholder-marron1 placeholder-opacity-70 text-gris1 text-base md:text-obsmall2 focus:border-marron1 outline-none focus:outline-none`
const twInputTextError = tw`border-red-800 bg-red-200 text-red-800 placeholder-red-800`

// const dataFetcher = () => {
//   fetch('https://api.npms.io/v2/search?q=react')
//         .then(response => response.json())
//         .then(data => this.setState({ totalReactPackages: data.total }));

// }

const RespuestaError = styled.div`
  ${tw`my-4`}
  ${tw`border-l`}
  ${tw`border-gris1`}
  ${tw`px-8`}
  ${tw`py-6`}
  ${tw`text-obmed2`}
  ${tw`text-gris1`}
`
const StyledHr = styled.hr`
  ${tw`border-t hidden md:block`}
  border-top: 1px solid #E0D6C8;
`

const BotonSuperior = styled.a`
  ${tw`inline-block border border-marron2 text-marron1 text-obmed py-2 px-4 mr-4`}
  span {
    ${tw`uppercase`}
    & p {
      display: inline;
    }
  }
  strong {
    ${tw`font-bold`}
  }
`

export default PagePrivada
